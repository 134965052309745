body .packDiv .card-body .card-title
{
    font-size: 21px;
    color: #35c8ea;
}
.check-pack
{
    position: absolute;
    right: 0;
    top: 0;
}
body .packDiv .card-body .text-muted {
    color: #ff3131!important;
}