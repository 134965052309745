.login-container {
  position: absolute;

  margin-top: 23%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  /* background-image:url('../../assets/BG-Image.jpg'); */
 
}

.form-container {
  background-color: #000000;
  color: #e8e8e8;
  font-size: 15px;
  padding: 21px;
  box-shadow: 2px 0px 3px 3px #f3f3f3;
  -webkit-box-shadow: 2px 0px 3px 3px #f3f3f3;
}
.form-container .form-control {
  background-color: #000;
  color: #e8e8e8;
}
.btn-clr {
  background-color: #e9b42a;
  font-weight: 600;
  font-size: 17px;
}
.img-width {
  width: 137px;
  margin-left: 171px;
  margin-bottom: 11px;
  margin-top: 11px;
}

._loading_overlay_content {
  position: fixed;
  width: 100%;
  min-height: 100%;
  background-color: #fdfdfd94;
}
body .css-fgquts-wrapper-Loader {
  position: absolute;
  width: 60px;
  height: 60px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.displaySpinner {
  display: block;
}
