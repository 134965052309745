:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: 0.75rem;
}
/* 
  body {
    background-image: url("../../Assets/bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  } */

#login-form {
  /* background-image: url("../../assets/bg.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-color: #958f88;
}

.card-signin {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card-signin .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
  padding: 2rem;
}

.form-signin {
  width: 100%;
}

.form-signin .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input {
  height: auto;
  border-radius: 2rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color:#958f88;
}

.btn-google {
  color: white;
  background-color: #958f88;
}

.btn-facebook {
  color: white;
  background-color: #958f88;
}

/* Fallback for Edge
      -------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #958f88;
  }
}

/* Fallback for IE
      -------------------------------------------------- */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color:#958f88;
  }
}

/* custom css  */
#login-form {
  /* height: 100%; */
}

.login-logo {
  display: block;
  width: 80px;
  margin: 10px auto;
}

h5.card-title.text-center {
  font-weight: 500;
}

#login-form h5.card-title.text-center {
  color: #3d3d3d;
}

.appTemp {
  background-color: blue;
 }

 .notika-status-area{height:65vh!important;}
 .LoginContainer{display: flex; justify-content:space-evenly; width:100%; margin-top:50px; }
 .Loginpage{width:45%; border-radius: 30px; border:10px solid #d0d3c8; min-height:350px;}
 .LoginWhite{width:100%; border-radius: 30px; min-height: 468px; background-color:#007dc9; border:10px solid #fff;}
 .LoginBlue{width:90%; border-radius: 30px; min-height: 300px; background-color: #007dc9;}
 .LoginWhite h3{font-size: 21px; font-weight:normal; color:#fff; border-bottom: 1px solid #fff; padding: 0px 20px 10px 0px; margin:20px 20px 20px 20px}
 .form-select{color:#333!important;}

 .form_label label{color:#fff!important}
 .form-group {
  margin-bottom: 15px;
  width: 100%;
}
.CaptchaSpace{width:100%; background-color:#c1c1c1; min-height: 70px;}
p.Code_text{color:#fff;}

.Login_text{width:45%; border-radius: 30px; border:10px solid #d0d3c8; min-height:350px;}
.MT_space label{font-size:12px;}
.LoginWhite_1{width:100%; border-radius: 30px; min-height: 450px; background-color:#fff; border:10px solid #fff;}
.LoginWhite_1 h3{font-size: 21px; font-weight:normal; color:#027bc8; border-bottom: 1px solid #d1d1d1; padding: 0px 20px 10px 0px; margin:20px 20px 20px 20px}
.LoginWhite_1 p{font-size:16px; font-weight: bold; color:#000; padding: 15px 20px 15px 15px; margin:0px;}
.LoginWhite_1 p.BoldNone{font-size:16px; font-weight: normal; color:#000; padding: 15px 20px 15px 15px; margin:0px;}
.LoginWhite_1 p.colorred{font-size:16px; font-weight: normal; color:#ff0000; padding: 15px 20px 15px 15px; margin:0px;}
