.displaySpinner{
    display: block;
}
.search-icons
{
    position: absolute;
    left: 17px;
    top: 17px;
}

.textBox:focus
{
    outline: none;
}

.stb-details .card-body
{
    height: 217px;
    overflow: auto;
}
.stb-details .card-footer
{
background-color: #ffffff;
border-top: 1px solid #f3f0f0;
}
body .card.stb-details .card-title{
    font-size: 15px;
    color: #19b8ce;
    line-height: 1.5;
}
.card.stb-details{
box-shadow: 1px 1px 3px 1px gainsboro;
-webkit-box-shadow: 1px 1px 3px 1px gainsboro;
}

