.form-label,.form-check-label
{
    font-weight: 600;
    color: #e9b42a;
    font-size: 15px;
}
.form-label span{
    color: #f19494;
}
.form-div
{
    box-shadow: 0px 0px 2px 0px #e0e5e0;
    -webkit-box-shadow: 0px 0px 2px 0px #e0e5e0;
    padding: 21px;
}
.form-div h3{
    font-size: 23px;
}
.backTo
{
    font-size:47px;
    float:left;
    cursor: pointer;
}