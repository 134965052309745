.login-container
{
    width: 500px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    margin: 141px auto;
}

.form-container
{
    background-color: #000000;
    color: #e8e8e8;
    font-size: 15px;
    padding: 21px;
    box-shadow: 2px 0px 3px 3px #f3f3f3;
    -webkit-box-shadow: 2px 0px 3px 3px #f3f3f3;
}
.form-container .form-control
{
    background-color: #000;
    color: #e8e8e8;

}
.btn-clr
{
    background-color: #2288dc;
    font-weight: 600;
    font-size: 17px;
}
.img-width
{
    width: 137px;
    margin-left: 171px;
    margin-bottom: 11px;
    margin-top: 11px;

}
.header-div
{
    background-color: #1869aa;
    float: left;
    width: 100%;
    color: #ffffff;
    padding: 7px;
    position: relative;
}

.login-container .form-label {

    color: #e8e8e8;

}
.main-div
{
    width: 500px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0
}

.logo-img
{
    float: right;
    width: 57px;
    right: 51px;
    position: relative;
}
.header-div h3{
    float: left;
    font-size: 21px;
    line-height: 2;
    margin-bottom: 0px;
    margin-top: 3px;
}

.txt-clr
{
    color:#2ba341;
}

.cards-width
{
    width: 24%;
    float: left;
    margin: 7px;
    box-shadow: 0px 0px 3px 0px grey;
    -webkit-box-shadow: 0px 0px 3px 0px grey;
    border-radius: 7px;
    font-weight: 600;
    border: 0px;
}

.widget-icons
{
    position: absolute;
    top: 21px;
    right: 11px;
    font-size: 70px;
}

.spinner{
    min-height: 100%;
    width: 100%;
    position: fixed;
    /* color: #2ba341; */
    
}
.spinnerContent{

    position: absolute;

    margin: auto!important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

}

