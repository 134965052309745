.checkBox {
  margin-left: 89%;
}

.displaySpinner {
  display: block;
}
.spinnerContent {
  position: absolute;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

input,
input:active,
input:focus {
  outline: none;
}
input.stb-transfer {
  width: 25%;
  padding: 4px;
  border: 2px solid #297186;
  border-radius: 20px;
  text-align: center;
}
