.button-box
{
    float: right;
    display: block;
    width: 100%;
    text-align: right;
    margin-bottom: 11px;
}
.search-div
{
    display: inline;
    float: left;
    border: 1px solid #f7f7f7;
    padding: 3px;
    margin-top: 3px;
    width: 17%;
    position: relative;
    border-radius: 7px;
}
.btn-align-right
{
    float: right;
}
.search-div svg
{
position: absolute;
    right: 0;
    top: 9px;
    right: 21px;
}
.search-div input
{
    border: 0px;
    width: 100%;
}
.search-div input:focus
{
    border:none;
}
table thead
{
    background-image: linear-gradient(90deg, #31bbbf, #61e5ea);
    background-image: -webkit-linear-gradient(90deg, #31bbbf, #61e5ea);
}
table tbody td
{
    border: 0px solid #f9f9f9;
}
tbody tr:nth-child(odd)
{
    background-color: #fbfbfb;
}
table th {
    padding: 7px;
    text-transform: uppercase;
    font-size: 15px;
    border: 1px solid #19dce4;
}

.checkbox{
    display: none;
}
.addon-price
{
    font-weight: 600;
    display: inline;
    float: left;
    margin-left: 21px;
    margin-top: 3px;
    font-size: 21px;
}
.refresh-icons
{
    font-size: 21px;
    transition: 1s ease;
    -webkit-transition: 1s ease;
    -o-transition: 1s ease;
    -moz-transition: 1s ease;
    -ms-transition: 1s ease;
    transition: 1s ease;
}
.refresh-icons:hover
{
    cursor: pointer;
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -moz-transform: scale(1.5);
  color:green;
}
body thead th{
    text-align: left;
}
.pack-div ul
{
    position: absolute;
    display: none;
    list-style: none;
    background-color: white;
    padding: 0px;
    box-shadow: 0px 1px 3px grey;
    z-index: 9;
    width:100%;

}
.pack-div
{
    position: relative;
}

.pack-div:hover > ul {
    display: block;
    max-height: 271px;
    overflow: auto;
}
.pack-div > div{
    text-align: center;
}
.pack-div > div{
    text-align: center;
}
table tbody td.pack-div
{
position: relative;
}
.pack-div > div a{
color: #007bff!important;
font-weight: 600;
text-align: center;
cursor: pointer;
}
.pack-div ul a li
{
    padding: 5px 7px;
    background-color: #fbfdff;
    border-bottom: 1px solid #e8e8e8;
    font-weight: 600;
    font-size: 13px;
    color: #000000;
}
.pack-div .show-pack:hover
{
    color: red!important;
}

.pack-div .show-pack
{
    color: #007bff!important;
    font-weight: 600;
    text-decoration: underline!important;
    cursor: pointer;
}