.card-title label {
  font-size: 17px;
  margin-left: 3px;
  margin-right: 7px;
}
.pull-right {
  float: right;
}
.card-shadow {
  box-shadow: 0px 0px 3px 0px #dadada;
  -webkit-box-shadow: 0px 0px 3px 0px #dadada;
}
.back-button {
  color: #ffffff;
  font-size: 41px;
  top: 7px;
  position: relative;
  float: left;
}
body .card-shadow .card-title {
  font-size: 17px;
  color: #000000;
}
.displaySpinner {
  display: block;
}
body .card-title label {
  font-size: 19px;
  color: rgb(0, 0, 0);
}
body .css-3pxmmn > div:nth-last-of-type(1) {
  padding: 51px;
}
.selected-nm {
  margin-left: 21px;
  margin-bottom: 11px;
  float: left;
  width: 100%;
  font-size: 17px;
  color: #6a8ddc;
}
.package-details {
  border-bottom: 0px solid #eaeaeaf7;
  border-radius: 0px;
  border-top: 0px;
}

.package-details .card-body {
  padding: 11px;
}
.package-details:nth-child(even) {
  background-color: #f0f7eb14;
}
.backTo {
  font-size: 47px;
  float: left;
  cursor: pointer;
}
.pack-box {
  padding: 0px 7px;
}
.btn {
  cursor: pointer;
}
.selected-div {
  position: relative;
  max-height: 121px;
  overflow: auto;
  border: 3px solid #f9f9f9;
  padding: 11px;
  border-radius: 7px;
}
.selected-div svg {
  margin-left: 11px;
  top: 3px;
  float: right;
  position: relative;
  font-weight: 600;
  cursor: pointer;
  border: 2px solid #ffffff;
  border-radius: 13px;
  font-size: 17px;
  padding: 0px;
}
.selected-div svg:hover {
  border: 2px solid red;
  color: red;
}
.bal-div label {
  color: red;
  font-size: 19px;
  font-weight: 600;
}
.bal-div > div {
  margin: 0px 21px;
}
.footer-div {
  padding: 11px;
  box-shadow: 3px 2px 3px 2px gainsboro;
  -webkit-box-shadow: 3px 2px 3px 2px gainsboro;
  border-top: 1px solid #f1f1f1;
}
.processing-div.card {
  background-color: #fff0;
  color: #ffffff;
  border: 0px solid rgba(0, 0, 0, 0.125);
  text-align: center;
}
.processing-div .rai-activity-indicator {
  display: block;
  margin-left: 27%;
  width: 100%;
}
.processing-div .card-footer {
  border: 0px;
  background: #ffffff00;
  padding: 0px;
}
.selected-div > label > label {
  display: none;
}
.popup-icon {
  position: relative;
  top: -3px;
}
.transactions-list .card .card-body > div {
  display: inline;
  color: #d61818;
  float: left;
  font-weight: 600;
  font-size: 17px;
  margin-right: 11px;
}
.transactions-list .card .card-body {
  padding: 7px 11px;
}
.transactions-list .card .card-body > div label {
  color: #000000;
}
thead {
  background-color: #35e0e8;
  color: #ffffff;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
}
tbody td {
  padding: 11px;
  border: 1px solid #f9f9f9;
}
thead td {
  padding: 11px 31px;
  border: 1px solid #f9f9f9;
}
tbody tr:nth-child(odd) {
  background-color: #f8ffff;
}
tbody tr td:nth-child(8) {
  background-color: #fdfdfd;
}
tbody {
  display: block;
  height: 287px;
  overflow: auto;
}
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* even columns width , fix width of table too*/
}
.popupwidth > div {
  width: 71%;
}
.popupwidth .stbicons {
  top: 15px;
}
.successicons {
  font-size: 107px;
  color: green;
  display: block;
  border: 7px solid green;
  margin: auto;
  border-radius: 81px;
  width: 150px;
  height: 150px;
  margin-top: 21px;
}
.confirm-popup {
  width: 437px;
}

.pack-div ul
{
    position: absolute;
    display: none;
    list-style: none;
    background-color: white;
    padding: 0px;
    box-shadow: 0px 1px 3px grey;
    z-index: 9;
    width:100%;

}
.pack-div
{
    /* position: relative; */
}

.pack-div:hover > ul {
    display: flex;
    max-height: 271px;
    overflow: auto;
    position: absolute;
    z-index:100;
}
.pack-div > div{
    text-align: center;
}
.pack-div > div{
    text-align: center;
   
    
    
}
.above > div{
  display:none ;
  margin-top:61px
}
.above:hover > div{

  position: absolute;
  display:block ;
   list-style: none;
   background-color: white;
padding : 0px;
box-shadow: 0px 1px 3px grey;
   z-index: 9;
  width: 12vw;
  top: 50px;
  overflow: auto;
max-height: 20vh;
                                                
  margin-top: 61px,

}
table tbody td.pack-div
{
position: absolute;
}
.pack-div > div a{
color: #007bff!important;
font-weight: 600;
text-align: center;
cursor: pointer;
}
.pack-div ul a li
{
    padding: 5px 7px;
    background-color: #fbfdff;
    border-bottom: 1px solid #e8e8e8;
    font-weight: 600;
    font-size: 13px;
    color: #000000;
}
.pack-div .show-pack:hover
{
    color: red!important;
}

.pack-div .show-pack
{
    color: #007bff!important;
    font-weight: 600;
    text-decoration: underline!important;
    cursor: pointer;
}

@media screen and (max-width: 1457px) {
  .popupwidth > div {
    width: 80%;
  }
}

@media screen and (max-width: 1170px) {
  .popupwidth > div {
    width: 100%;
  }
}
.displaySpinner {
  display: block;
}
.transactions-btn svg {
  font-size: 61px;
  display: block;
  text-align: center;
  margin: auto;
}
.transactions-btn .btn {
  font-weight: 600;
  margin-right: 27px;
  background-color: #fdfdfd;
  -moz-box-shadow: 0 0 7px #cecbcb;
  -webkit-box-shadow: 0 0 7px #cecbcb;
  box-shadow: 0 0 7px #cecbcb;
}
.transactions-btn .btn:nth-of-type(1) {
  color: #7dca7d;
}
.transactions-btn .btn:focus {
  -moz-box-shadow: inset 0 0 7px #cecbcb;
  -webkit-box-shadow: inset 0 0 7px #cecbcb;
  box-shadow: inset 0 0 7px #cecbcb;
}
.transactions-btn .btn:nth-of-type(2) {
  color: #f98d4c;
}
.transactions-btn .btn:nth-of-type(3) {
  color: #d6d225;
}
table .total-records {
  height: 463px;
}
table th {
  padding: 7px;
}

body .notify-div .card-title {
  font-size: 17px;
  color: #16a7caf5;
}
.notify-div .card-body div {
  font-weight: 600;
}

.notify-div .card {
  box-shadow: 1px 1px 3px 1px gainsboro;
  -webkit-box-shadow: 1px 1px 3px 1px gainsboro;
  height: 187px;
  overflow: auto;
  background: linear-gradient(180deg,#ffefba,#fff);
}

.notify-div {
  height: 561px;
  overflow: auto;
}

.profileContentContainer {
  margin-top: 10px;
  transition: all 1s;
}
.profileContentContainer:hover {
  transform: scale(1.1);
  background-image: linear-gradient(to bottom right, #7774ff, #86ebff);
  color: white;
}
.constentValueStyle {
  /* color:"gray",fontSize:18,marginLeft:"5%"  */
  color: gray;
  font-size: 18px;
  margin-left: 5%;
}
.constentValueStyle:hover {
  /* color:"gray",fontSize:18,marginLeft:"5%"  */
  color: white;
  font-weight: bold;
}
.dynamicValue {
  /* color:"gray",fontSize:18,justifySelf:"flex-end",fontWeight:"bold",marginLeft:"50%" */
  color: gray;
  font-size: 18px;
  font-weight: bold;
  margin-left: 50%;
}

.dynamicValue:hover {
  /* color:"gray",fontSize:18,justifySelf:"flex-end",fontWeight:"bold",marginLeft:"50%" */
  color: white;
}
