.card-shadow
{
    box-shadow:0px 3px 3px 0px #e9dfdf;
    -webkit-box-shadow:0px 3px 3px 0px #e9dfdf;
}

.stbicons
{
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: 17px;
    margin: auto;
    /* bottom: 0; */
    color: #1bdc54;
    font-weight: 600;
    border: 2px solid #1bdc54;
    padding: 3px;
    border-radius: 7px;
    font-style: normal;
}
.stbicons:hover{
    background: #1bdc54;
    color: #ffffff;
    cursor: pointer;
}
.card-title
{
    font-size: 17px;
    color: #19b8ce;
    margin-bottom: 7px;
}
body .card-shadow .card-title
{
    font-size: 17px;
    color: #19b8ce;
}