.displaySpinner {
  display: block;
  position: absolute;
  
}

body .card-title {
  font-size: 31px;
  color: #ffffff;
}
.errorBlock {
  font-size: 20px;
  color: red;
  margin-left: 40%;
  position: absolute;
  top: 11%;
  bottom: 0;
  margin: auto;
  width: 30%;
  left: 0;
  right: 0;
  font-weight: 600;
}
.card-text {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
}

.card.text-white {
  border: 0px;
  border-radius: 7px;
}
.spinnerContent {
  position: absolute;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.fontSize {
  font-size: 35px;
  position: absolute;
  top: 15px;
  right: 11px;
  color: #ffffff;
  font-weight: 600;
  border: 3px solid #82c1f3;
  padding: 3px;
  background-color: #2f91e1;
  border-radius: 23px;
  cursor: pointer;
}
.searchBT {
  /* backgroundColor:"transparent";  marginLeft:10,border:"2px solid white",color:"white",fontWeight:"bold",fontSize:13; */
  background-color: transparent;
  margin-left: 10px;
  border: 2px solid white;
  color: white;
  font-weight: bold;
  font-size: 13px;
}
.searchBTN:hover {
  background-color: white;
  color: black;
}
.name {
  background-color: transparent;
  margin-left: 10px;
  border: 2px solid white;
  color: white;
  font-weight: bold;
  font-size: 13px;
}
.name:hover {
  background-color: white;
  color: black;
}

body .card-body .card-title {
  font-size: 31px;
  color: #ffffff;
}
.dashboard-card .card:not(.no-link):hover .card-body svg {
  font-size: 61px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transition: 1s ease;
  -webkit-transition: 1s ease;
  -o-transition: 1s ease;
  -ms-transition: 1s ease;
  -moz-transition: 1s ease;
}

.dashboard-card .card:not(.no-link):hover {
  cursor: pointer;
}
@media screen and (max-width: 1300px) {
  .widget-icons {
    font-size: 55px;
    margin-top: 0rem;
    right: 7px;
  }
  .card-text {
    margin-top: 1em;
  }
}

@media screen and (max-width: 767px) {
  .widget-icons {
    font-size: 73px;
  }
  .card-text {
    margin-top: 2em;
  }
}
.input-STB::placeholder {
  color: #ffffff;
}

.lcoSearch {
  position: absolute;
  display: block;
  list-style: none;
  background-color: white;
  padding: 0px;
  box-shadow: 0px 1px 3px grey;
  z-index: 9;
  width: 30vw;
  top: 50px;
}

@media screen and (max-width: 767px) {
  .card-text {
    margin-top: 0em;
  }
}

/*custom css*/

/* log in */
.login-container {
  margin: 70px auto;
}

.name:hover,
.name:active,
.name:focus {
  background-color: #2b5b84;
  color: white;
  outline: none;
}

.header-div {
  /* background-image: linear-gradient(
    to right top,
    #051937,
    #0a4066,
    #006c96,
    #009cc3,
    #12cfeb
  ); */
  background-color: #040B26;
}

input.input-STB {
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border: 2px solid #fff !important;
  color: white !important;
}

svg.text-center {
  width: 82px;
  height: 77px;
  position: relative;
  top: -40px;
  color: #ffffff;
  border-radius: 68px;
  padding: 10px;
}

@media (min-width: 1024px) {
  .dashboard .col-md-3 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .dashboard .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .dashboard .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .dashboard .col-md-2 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .mt-5.stock-operation.row .col-md-3 {
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    max-width: 33%;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .transcation.row .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 33%;
    max-width: 33%;
  }
}

@media (max-width: 570px) {
  .margin-center {
    margin: 0 30%;
  }
}
.circle {
  margin-bottom: -30px;
}

.card-body {
  margin-bottom: 20px;
}

svg.search {
  font-size: 25px;
}

.name {
  margin-left: 12px;
  font-weight: bold;
  font-size: 14px;
  background-color: #2b5b84;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: -1px;
  border-radius: 20px;
  width: 36px;
  height: 35px;
}

svg.fontSize.login {
  border: 3px solid rgb(25, 176, 40);
  background-color: rgb(21, 171, 36);
  margin-right: 50px;
}

svg.fontSize.login:hover {
  border: red;
  background-color: red;
}

.stb-details .card-body {
  height: 220px !important;
  overflow: hidden !important;
}
.stb-details .card {
  border: 1px solid rgba(130, 130, 130, 0.83) !important;
}
.customer-detail-heading {
  margin-top: 8px;
  font-size: 15px !important;
  color: #000000 !important;
  line-height: 1.5;
  text-align: center;
  font-weight: bold;
}

label.align-pu {
  float: left;
  margin: 5px 5px;
  font-size: 18px;
}

.customer-name.col-md-8.col-sm-8 {
  font-size: 20px;
  color: #05547c;
  font-weight: 600;
}
.price.card-title.h5 {
  margin-top: -15px;
}

@media (min-width: 768px) {
  .cNYSzp {
    background: #bfec99;
    width: 70% !important;
    padding: 7px 47px;
    border-radius: 37px;
    position: relative;
    margin: 0 auto;
  }
}

.transaction-card {
  width: 20rem;
  text-align: center;
  background-color: #fff;
  margin: 0px auto;
  /* padding: 40px; */
  padding: 40px 40px 50px;
}

.transaction-card svg {
  width: 70px;
  height: 70px;
  position: relative;
  top: -70px;
  color: #fff;
  /* border-radius: 68px; */
  /* padding: 5px; */
  /* background: linear-gradient(to right, #6066d6, #7f84ee); */
}

li.search-list {
  font-size: 14px !important;
}

/* profile */

.profile .row {
  width: 85%;
  /* background: white; */
  background: #092533;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
}

.profile .circle {
  text-align: center;
  margin-bottom: 10px;
  margin-right: 45px;
}

.profile svg {
  height: 80px;
  width: 80px;
  color: white;
  background: #092533;
  border-radius: 40px;
  padding: 11px;
  margin-top: 40px;
}

.profile .col-md-4.col-sm-12,
.profile .col-md-8.col-sm-12 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
}

.profile label {
  font-size: 17px;
  color: #c5c5c5;
  margin-right: 5px;
}

.profile .edit {
  background: grey;
  color: white;
  margin-left: 25px;
  cursor: pointer;
  padding: 2px 5px;
  font-size: 15px;
}

tbody tr td:nth-child(8) {
  background-color: transparent !important;
}

.table th,
.table thead {
  padding: 7px;
  text-transform: uppercase;
  font-size: 15px;
  border: 1px solid #ffff;
  /* background-color: #093559 !important; */
  background: #366cc8;
  color: #fff;
}

table.table.table-striped {
  text-align: center;
}

.table thead th {
  vertical-align: bottom;
  /* border-bottom: 2px solid #1569bd; */
  text-align: center;
}

table .total-records {
  height: 500px;
}

.jOzkvp {
  margin-bottom: 40px;
}

.notify-div .card {
  height: 200px !important;
  overflow: hidden !important;
  /* background: #f1f1f1; */
  /* background: linear-gradient(to right, #00b4db, #0083b0); */
  /* background: #ff9068; */
  background: #ee9ca7;
}

.notification .icon {
  margin: 5px 0px;
}

.notification .icon svg.mr-3 {
  width: 20px;
  height: 20px;
}

.ireVdR {
  margin-bottom: 5px;
  padding: 2px;
  font-size: 21px;
  line-height: 2.3;
  border: 0px solid #ececec;
  text-align: center;
  margin-top: -15px;
}

.lcocode {
  display: inline-block;
  color: #f14444;
  font-size: 14px;
  margin-left: 30px;
}
.lcocode span {
  color: black;
  margin-left: 0px;
  font-size: 16px;
}

/* bottom nav */
/* .bottom-nav-active {
  color: white;
} */

.undrawimg {
  height: 150px !important;
  width: 300px !important;
  margin-top: 20px;
  display: block;
  margin-bottom: 10px;
  margin-left: 20px;
}

.stb-search input {
  width: 25%;
  border-radius: 30px;
  border: 2px solid #060606;
  text-align: center;
  height: 35px;
}
.stb-returns input {
  width: 17%;
  border-radius: 30px;
  border: 2px solid #060606;
  text-align: center;
  height: 35px;
}

.ejdDjE {
  color: #004085 !important;
}
/* .ejdDjE {
  color: #ffffff !important;
} */
svg.details {
  color: #1a237e;
  font-size: 20px;
}

textarea {
  resize: none;
}

li.data-product {
  list-style: none;
  display: inline-block;
}

.fLxwLo {
  /* background: linear-gradient(to right, #b993d6, #8ca6db); */
  /* background: linear-gradient(to right, rgb(173, 83, 137), rgb(60, 16, 83)); */
  padding: 9px;
  line-height: 1.5;
}
/* .bNwSfB {
  color: #ffffff !important;
} */

.edit-address label {
  margin-top: -100px;
}

#edit-customer-details input {
  width: 70%;
}

@media (max-width: 945px) {
  .edit-address label {
    margin-top: -30px;
  }
  #edit-customer-details input {
    width: 100%;
  }
}

table.table.table-striped {
  font-size: 14px;
}
.pay-disc {
  margin-left: 10%;
}

@media (max-width: 1050px) {
  .pay-disc {
    margin-left: 5%;
  }
}

.iWIjkB {
  color: #132275 !important;
}

.bouncechk {
  display: block !important;
}
